<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mpage-section mpage-head">
				<div id="mpage-player" class="mpage-player" v-show="liveInfo.aliyun_status==2"></div>
				<img v-show="liveInfo.aliyun_status!==2 && liveInfo.cover0" :src="liveInfo.cover0" @error="imageLoadErrorLive" alt="" class="live-cover">
				<div class="mlive-user">
					<div class="user-info">
						<img src="@/assets/images/item/live-avatar-100-100.png" alt="" class="avatar">
						<div class="item-box">
							<div class="name">{{liveInfo.title}}</div>
							<div class="text">{{$t('mobile.live.text7')}} {{storeInfo.count_fans}}</div>
						</div>
					</div>
					<div class="sidebar">
						<div class="btn disabled" @click="handleUnFollow" v-if="liveInfo.is_collect==1">{{$t('mobile.actions.unfollow')}}</div>
						<div class="btn" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</div>
						<div class="text">{{$t('mobile.live.text8')+':'}} {{liveInfo.live_id?liveInfo.live_id:$route.params.id}}</div>
					</div>
				</div>
				<div class="mlive-status" v-if="liveInfo.aliyun_status!==2">{{$t('mobile.empty.playback')}}</div>
			</div>
			<div class="mpage-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text4')}}</span>
					<router-link :to="{name: 'mLiveGoods',params:{id:$route.params.id}}" class="next">{{$t('mobile.actions.moreBaby')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mgoods-list">
					<router-link class="item" v-for="(item,index) in liveGoods" :key="index" :to="{name:'mGoodsInfo',params:{id:item.goodsId},query:{live:liveInfo.live_id}}">
						<div class="item-pic"><img :src="item.defaultImage" @error="imageLoadErrorGoods" alt=""></div>
						<div class="item-main">
							<div class="name">{{item.goodsName}}</div>
							<div class="price">￥<span>{{item.price}}</span></div>
						</div>
					</router-link>
				</div>
			</div>
			<div class="mpage-section">
				<div class="sction-title">
					<span class="name">{{$t('mobile.live.text13')}}</span>
					<router-link :to="{name: 'mLiveListPlayback'}" class="next">{{$t('mobile.live.text14')}}<i class="el-icon-arrow-right"></i></router-link>
				</div>
				<div class="mlive-list">
					<router-link class="item" v-for="(item,index) in liveList" :key="index" :to="{name:'mLivePlayback',params:{id:item.live_id}}">
						<img :src="item.cover0" @error="imageLoadErrorLive" alt="" class="pic">
						<div class="item-main">
							<div class="name">{{item.title}}</div>
							<div class="text">
								<el-tag type="info" effect="dark">{{$t('mobile.live.tag3')}}</el-tag>
								<span>{{item.views}} {{$t('mobile.tags.views')}}</span>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { fetchShopInfo, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	import { getLiveList, getLiveGoods, fetchLivePlayback } from '@/api/mobile/live';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				liveInfo: {
					count_fans: 0,
					aliyun_status: 2,
					statistics: {}
				},
				storeInfo: {},
				liveGoods: [],
				liveList: [],
				aliPlayer: null
			}
		},
		mounted() {
			this.getLiveInfo();
			this.getLiveGoods();
			this.getLivelist();
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			imageLoadErrorLive(e) {
				e.target.src = this.$imageErrorLive;
			},
			getLiveInfo(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchLivePlayback(param).then(async response => {
					const { data } = response
					this.liveInfo = data;
					this.getStoreInfo(data.store_id);
					if (data.aliyun_status == 2 && data.play_back_url_https) {
						this.createAliplayer(data.play_back_url_https, data.cover0)
					}
					setTimeout(async () => {
						await browseStore({ live_id: data.live_id, store_id: data.store_id, from: 1 })
					},3000)
				})
			},
			createAliplayer(source, cover) {
				let props = {
					"id": "mpage-player",
					"source": source,
					// "cover": cover,
					"width": "100%",
					"height": "100%",
					"autoplay": false,
					"isLive": true,
					"rePlay": false,
					"playsinline": true,
					"preload": true,
					"controlBarVisibility": "always",
					"useH5Prism": true
				}
				this.aliPlayer = window.Aliplayer(props);
				let that = this;
				this.aliPlayer.on('error',function(e){
					that.liveInfo.aliyun_status = 3;
					document.querySelector('.prism-ErrorMessage').setAttribute('style','display:none');
				})
			},
			getStoreInfo(){
				const id = this.liveInfo.store_id;
				fetchShopInfo({store_id: id}).then(response => {
					this.storeInfo = response.data;
				})
			},
			getLiveGoods(){
				let param = {
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				getLiveGoods(param).then(response => {
					this.liveGoods = response.data.list;
				})
			},
			getLivelist(){
				let param = {
					isall: 1,
					issubscribe: 1,
					status: 2,
					page_index: 0,
					page_size: 4,
					live_id: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				getLiveList(param).then(response => {
					this.liveList = response.data.list;
				})
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'live',
					followId: this.$route.params.id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					// this.getStoreInfo();
					this.$router.go(0)
				})
			},
		},
		watch: {
			'$route': {
				handler: function(val, oldVal) {
					Object.assign(this.$data, this.$options.data());
					this.getLiveInfo();
					this.getLiveGoods();
					this.getLivelist();
				},
				deep: true
			},
		}
	}
</script>
<style lang="scss" scoped>
	.mhome-wrap {
		.mpage-section {
			margin-bottom: 0.32rem;
			background-color: #FFFFFF;
			.sction-title {
				height: 1.28rem;
				padding-right: 0.384rem;
				border-left: 0.128rem solid red;
				background-color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.name {
					font-size: 0.448rem;
					padding-left: 0.256rem;
				}
				.next {
					color: red;
					font-size: 0.416rem;
					i {
						margin-left: 0.128rem;
					}
				}
			}
		}
		.mpage-head {
			width: 100%;
			height: calc(100vh - 120px);
			position: relative;
			.mpage-player {
				width: 100%;
				height: 100%;
				border: none;
				background-color: #000000;
			}
			.live-cover {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.mlive-user {
				top: 0;
				width: 100%;
				padding: 0.384rem;
				position: absolute;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.user-info {
					display: flex;
					padding: 0.128rem 0.512rem 0.128rem 0.128rem;
					border-radius: 0.736rem;
					background-color: rgba(0,0,0,.25);
					box-shadow: 0 0 0.352rem rgba(255,255,255,.5);
					.avatar {
						width: 1.216rem;
						height: 1.216rem;
						border-radius: 50%;
					}
					.item-box {
						max-width: 6.4rem;
						margin-left: 0.192rem;
						color: #FFFFFF;
						.name {
							font-size: 0.416rem;
							height: 0.64rem;
							line-height: 0.64rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.text {
							font-size: 0.384rem;
							line-height: 0.576rem;
						}
					}
				}
				.sidebar {
					text-align: center;
					.btn {
						height: 0.768rem;
						line-height: 0.768rem;
						border: 1px solid #f40;
						color: #f40;
						display: inline-block;
						font-size: 0.416rem;
						padding: 0 0.256rem;
						border-radius: 0.416rem;
					}
					.btn.disabled {
						color: #999999;
						border-color: #999999;
					}
					.text {
						color: #FFFFFF;
						font-size: 0.384rem;
						line-height: 0.512rem;
						margin-top: 0.128rem;
					}
				}
			}
			.mlive-status {
				top: 50%;
				left: 50%;
				color: #FFFFFF;
				font-size: 0.576rem;
				position: absolute;
				white-space: nowrap;
				transform: translateX(-50%);
				line-height: 1.5;
				padding: 0.256rem 0.64rem;
				border-radius: 0.704rem;
				background-color: rgba(0,0,0,.25);
				box-shadow: 0 0 0.352rem rgba(255,255,255,.5);
			}
		}
		.mgoods-list {
			overflow: hidden;
			.item {
				float: left;
				width: 50%;
				.item-pic {
					width: 100%;
					height: 6rem;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.item-main {
					padding: 0.32rem;
					.name {
						color: #333333;
						font-size: 0.416rem;
						height: 1.088rem;
						line-height: 0.544rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.price {
						color: #f40;
						font-size: 0.352rem;
						height: 0.544rem;
						line-height: 0.544rem;
						span {
							font-size: 0.448rem;
						}
					}
				}
			}
		}
		.mlive-list {
			overflow: hidden;
			.item {
				width: 50%;
				float: left;
				position: relative;
				.pic {
					width: 100%;
					height: 6rem;
					object-fit: cover;
				}
				.item-main {
					left: 0;
					bottom: 0;
					width: 100%;
					padding: 0.32rem;
					box-sizing: border-box;
					position: absolute;
					.name {
						color: #FFFFFF;
						font-size: 0.416rem;
						height: 0.544rem;
						line-height: 0.544rem;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-bottom: 0.128rem;
					}
					.text {
						color: #FFFFFF;
						font-size: 0.384rem;
						line-height: 0.544rem;
						.el-tag {
							border: none;
							height: 0.512rem;
							line-height: 0.512rem;
							font-size: 0.32rem;
							padding: 0 0.192rem;
							border-radius: 0.256rem;
							margin-right: 0.192rem;
						}
					}
				}
			}
		}
	}
</style>